@import '../../scss/client_master.scss';

$InputPanel-border: 1px solid #ccc !default;
$InputPanel-border-top: null !default;
$InputPanel-border-bottom: null !default;
$InputPanel-border-left: null !default;
$InputPanel-border-right: null !default;
$InputPanel-padding: 2em !default;
$InputPanel-box-shadow: null !default;
$InputPanel-background-color: #fff !default;
$InputPanel-heading-margin-bottom: 0 !default; 
$InputPanel-heading-title-font-size: 20px !default;
$InputPanel-heading-title-font-weight: 400 !default;
$InputPanel-heading-title-font-family: Arial !default;

.InputPanel {
  background-color: $InputPanel-background-color;
  border: $InputPanel-border;
  border-bottom: $InputPanel-border-bottom;
  border-left: $InputPanel-border-left;
  border-right: $InputPanel-border-right;

  padding: 30px;

  box-shadow: $InputPanel-box-shadow;

  .heading {
       position: relative;
    display: flex;
    align-items: center;
    margin-bottom: $InputPanel-heading-margin-bottom;

    .collapser {
       position: relative;
       top:-11px;

       .btn {
              padding:0 !important;

              &:before {
                     content:"";
                     display: inline-block;
                     width:20px;
                     height:20px;
                     background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976314 7.31658 -0.0976314 7.70711 0.292894L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711Z' fill='%23142C41'/%3E%3C/svg%3E%0A");
                     background-repeat: no-repeat;
                     background-position: center center;
              }

              &.collapsed:before {
                     transform: rotate(180deg);
              }
       }
    }

    .title {
      flex-grow: 1;
      font-size: $InputPanel-heading-title-font-size;
      font-weight: $InputPanel-heading-title-font-weight;
      margin:0 0 30px;
      line-height: 1.2em;
    }

    .btn-link {
      font-size: 22px;
      padding-bottom: 10px;
      color: #212529;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .form-group {
       margin-bottom:25px !important;

       &:last-child {
              margin-bottom:0 !important;
       }

       label {
              text-transform: uppercase;
              font-size:13px;
              color:#142C41;
       }
  }
}
