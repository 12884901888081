.RateOptionsWizard {
  .ButtonGroupChargingPatterns {
    max-width: 567px;
    margin: 0 auto;
  }

  p.lead {
       font-weight:600;
       font-size:18px;

       .highlight {
              color: #617E25;
       }
  }

  .checkmark {
       max-width:50px;
  }

  .modal-title {
    small {
       font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-transform: none;

      color: #666366;
    }
  }

  .modal-body {
    min-height: 300px;

    p.h1 {
       font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
    }

    p.tip {
       font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;

      color: #666366;
    }

    .form-group-half {
      display:flex;

      .form-group {
        width:50%;

        label {
              text-align: left;
              display: block;
        }

        &:first-child {
          margin-right:15px;
        }

        &:last-child {
          margin-left:15px;
        }
      }
    }

    .form-group {
      .label-range {
        text-align: center;
        font-family: "Proxima Nova";
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 14px !important;
        text-align: center !important;
        text-transform: uppercase !important;
        margin: 0 auto !important;
        display: block !important;
      }

      input[type='range']::-webkit-slider-runnable-track {
        color: #666366 !important;
      }

      p {
       font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        color: #666366;
      }
    }

    .end-text-container {
       p.lead {
              font-family: "Proxima Nova";
              font-size:24px;
              font-weight: 500;
       }
    }
  }

  .modal-footer {
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .btn {
       font-family: "Proxima Nova";
      min-width: 200px;
      padding:15px;
      @media (max-width: 767px) {
        display: block;
        margin-bottom: 0.5em;
      }

      &.next-btn {
              background-color: #142C41;
              color: #ffffff;
              font-weight: 600;
              border: 2px solid #142C41;
      }

      &.btn-secondary {
       font-weight: 600;
        border:2px solid #142C41;
        background-color:white;
        color:#142C41;
      }
    }
  }

  label {
       font-family: "Proxima Nova";
    text-transform: uppercase;
  }

  .ButtonGroup {
    .btn {
      background-color: #F4F4F4;
      border-radius: 0;
      height: 80px;

      &.active {
        background-color: #142C41;
        color: #ffffff;
      }
    }
  }
}
