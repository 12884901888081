@import 'src/client_customizations/scss/client_master.scss';

/* Apply yellow span underline for mobile views */
.Header {
  background-color: $Header-background-color;
  font-family: 'BentonSans' !important;
  border-bottom: 1px solid #dbe1ed;
  z-index: 3;
  position: relative;

@media (max-width: 576px) {
  .homepagelogo {
     height: 32px !important;

  }
}
@media (min-width: 577px) {
  .homepagelogo {
      width: 305px;
      height: 50px;
  
    }
}

  
  a {
    color: #142C41;
    &:hover {
      text-decoration: none !important;
    }
  }
  .bottomHeader {
    background-color: black;
    display: flex;
    justify-content: center;
    color: white;
    margin: 0;
    padding: 5px;
  }
  .mobile-top-header {
    margin: 0;
  }
   
  .navbar {
    @media (min-width: 576px) {
        .navbar {
          padding: 37px 52px !important;
        }
      }

    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      img {
        width: 160px;
      }
    }
    .navbar-collapse {
      justify-content: space-around;
      a.active {
        span {
          // color: $Header-navbar-link-color;
          font-weight: 700;
          text-decoration: none;
        }
      }

      .navbar-nav {
        display: flex;
        column-gap: 36px;
        align-items: flex-start;
      }

      .dropdown-item {
        border-radius: 0;
      }
    }

    .language-active { 
      border-bottom: 2px solid #F0512C;
      padding-bottom: 5px;
    }
    .dropdown-menu {
      top: 50px;
      border-radius: 3px;

      .dropdown-item {
        font-size: 13px;

        &.item {
          color: #142C41;
        }
        &.off {
          color: #142C41;
          margin-left: 4px;
        }
        &.active,
        &:active {
          border-left: 3px solid #F0512C;
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
  } 
  .nav-item {
    font-family:'Outfit';
    font-size: 20px;
    font-weight: 200;
    line-height: 26px;
    letter-spacing: 0em;
    margin: 20px 0px 20px 0px 
    // margin: 10px 50px 0px 50px;
  }
  .input-zipcode {
    justify-content: flex-end;
    background-color: white;
    margin: inherit;
    .input-group-text {
      background-color: white;
      border: none;
    }
    .btn {
      color: #005f83;
    }
  }
  li {
    display: flex;
  }
  .dropdown-menu {
    background-color: #232425;
    min-width: 0;
    .dropdown-item:hover {
      background-color: #0073b1;
      color: #f9f9f9;
    }
    .dropdown-item {
      white-space: normal;
    }
  }
  span {
    font-size: 20px;
    font-weight: 200;
    @media (max-width: 766px) {
        &.mobile-header-item {
            font-size: 32px !important;
          }
      }
   
  }
  .form-inline {
    flex-flow: row;
  }
}
.skip-to-content-link {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
}
a.skip-to-content-link {
  &:active {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
  &:focus {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
  &:hover {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
}
.navbar-toggler {
  border: none;
  border-color: $Header-navbar-toggler-border-color;
  color: $Header-navbar-toggler-color;
  .navbar-toggler-icon {
    height: 1.25em;
    width: 1.25em;
    background-image: url('../../../../client_customizations/assets/images/icons/navbar-icon.svg');
  }
}
@media (min-width: 992px) {
  .Header {
    .input-zipcode {
      padding: 0.5rem;
    }
    .navbar {
      max-height: 110px;
      padding: 0.75rem;
      .navbar-collapse {
        a {
          font-weight: 200;
          line-height: 26px; /* 130% */
          text-decoration: none;
          font-family:'Outfit';
        }
        a.active {
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 766px) {
  .Header {
    #update-zipcode-button {
      margin-left: 8px;
    }
    .input-group-text {
      padding: 3px 3px;
    }
  }
}
