.evHead {
  font-family: 'Outfit';
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: 0em;
  color: #142C41;
  text-align: center;

  @media(max-width:991px) {
       font-size:35px;
  }
}

.lead {
  font-family: 'Proxima Nova';
  color: #142C41;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0px;
  text-align: center;

  max-width: 931px;
  margin: 0 auto 50px;

  @media(max-width:991px) {
       font-size: 16px;
  }
}

.disclaimer {
  margin-top: 16px;

  font-family: 'Proxima Nova';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.score-options-header {
    font-family: "Outfit";
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    color: #142C41;
}