@import "../../scss/client_master.scss";

.RateAdvisor {
       padding-top:64px !important;

       h1.header {
              font-size:40px;
              font-family: "Proxima Nova";
              margin:0 0 15px;
       }

       .chart-controls {
              margin:25px 0;
              display: flex;
              justify-content: space-between;

              @media(max-width:768px) {
                     display:block !important;
              }

              .cta-btn {
                     background-color: #142C41;
                     color:#fff;
                     padding:15px 35px;
                     font-weight: 500;
                     font-size: 14px;
              }
       }

       .graph-disclaimer {
              font-size:12px;
              margin:15px 0 20px;
       }
}

.rate-comparison-title {
       background: #9BA4B8;
       padding: 15px 25px;
       border-top-left-radius: 5px;
       border-top-right-radius: 5px;

       h3 {
              color: #fff;
              margin: 0;
              font-size: 20px;
              font-weight: 400;
       }
}

.chart-details-container {
       padding:25px;
       background-color: #ffffff;
       border:1px solid #9BA4B8;

       .chartBtn-ae {
              background:#142C41;
       }

       .title {
              margin-bottom: 10px;
       }

       .cta-btn {
              background-color: #fff;
              border:1px solid #142C41;
              color:#142C41;
              padding:15px 35px;
              font-weight: 500;
              font-size: 14px;
              margin:25px 0 0;
              display: inline-block;
       }
}

.rateAdvisorCaption {
       margin:25px 0 0;
}

.chart-data-toggle {
       margin-right:10px;

       @media(max-width:768px) {
              margin-bottom:20px;
       }

       span {
              font-size:14px;
              position:relative;
              top:-6px;
              padding:0 10px 0 5px;
       }
}

.RateComparison {
  min-height: 500px;

  .RateTotals {
    .title {
      font-size: 12px;
    }

    .lowest {
      color: $green;
    }
  }

  .btn {
    font-weight: bold;
    border-radius: 0;

    &.btn-default {
      color: #57595D;

      &.active {
        background-color: #005F83;
        color: #ffffff;
        border: 1px solid transparent;
      }
    }
  }

  label {
    font-weight: 700;
    text-transform: uppercase;
  }

  .charging-program-link {
    font-size: 11px;
    font-weight: bold;
  }

  .MobileRateTotals {
    background-color: #fff;
    padding: 1em;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14);

    .MobileRateTotal {
      text-align: center;
      .total-container {
        background-color: #F6F8FB;
        padding: 1em;

        .lowest {
          color: $green;
        }
      }

      hr {
        padding: 1rem 0;
      }
    }
  }
}
