$RateAdvisorChart-border: none !default;
$RateAdvisorChart-border-top: null !default;
$RateAdvisorChart-border-bottom: null !default;
$RateAdvisorChart-border-left: null !default;
$RateAdvisorChart-border-right: null !default;
$RateAdvisorChart-padding: 1em !default;
$RateAdvisorChart-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14) !default;
$RateAdvisorChart-title-text-align: center !default;
$RateAdvisorChart-title-text-transform: uppercase !default;
$RateAdvisorChart-title-font-size: 18px !default;
$RateAdvisorChart-background-color: #fff !default;
$RateAdvisorChart-title-font-family: Roboto;

.RateAdvisorChart {
  
  background-color: $RateAdvisorChart-background-color;
  border: $RateAdvisorChart-border;
  border-top: $RateAdvisorChart-border-top;
  border-bottom: $RateAdvisorChart-border-bottom;
  border-left: $RateAdvisorChart-border-left;
  border-right: $RateAdvisorChart-border-right;

  padding: $RateAdvisorChart-padding;

  box-shadow: $RateAdvisorChart-box-shadow;

  .title {
    text-align: $RateAdvisorChart-title-text-align;
    text-transform: $RateAdvisorChart-title-text-transform;
    font-size: $RateAdvisorChart-title-font-size;
    font-family: $RateAdvisorChart-title-font-family;
  }

 .RateAdvisorChart_canvas_container {
   @media(max-width:768px) {
     height: auto;
   }

   height: 755px;
   width:100%;
   position:relative;
 }

  canvas {
       @media(max-width:768px) {
              width: 100% !important;
              height: 400px !important;
       }
  }
}
