.FAQCard {
  margin: 3rem 0rem;
  .row-container {
    max-width: 100% !important;
    flex-direction: column !important;
  }

  font-family: 'Outfit';

  .container {
    display: flex;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 2rem;
    @media (max-width: 768px) {
      grid-template-columns: inherit;
      display: flex;
    }
  }

  .text {
    display: grid;
    background-color: white;
    font-size: 16px;
    padding: 2rem;
    font-weight: 150;

    .text-title {
      font-family: 'Outfit';
      display: flex;
      font-weight: 500;
    }

    .small-space {
      margin-top: 1rem;

      p {
        font-family: 'Proxima Nova';
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5em;
      }
    }

    .large-space {
      margin-top: 1rem;
    }

    .bottom,
    .top,
    .list {
      font-family: 'Proxima Nova' !important;
    }
    .list {
      li {
        margin: 10px 0px;
      }
    }
  }

  .image {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    @media (max-width: 768px) {
      background-size: inherit;
      background-position: inherit;
    }

    .icon {
      @media (max-width: 1150px) {
        width: 99%;
      }
    }
  }
}
