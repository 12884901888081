@import 'src/client_customizations/scss/client_master.scss';
$color_1: #666666;
$color_2: #333333;
$color_3: #252525;

.RenderItemTitle {
  font-size: 12;
  font-weight: 200;
  text-align: 'left';
  margin-bottom: 10;
  font-family: 'Proxima Nova';
  color: 'black';
}
.uppercase {
  text-transform: uppercase;
}
.RenderItemValue {
  font-weight: bold;
  color: $RenderItem-item-value-color;
  .denominator {
    font-weight: 700;
    font-size: 0.8rem;
    color: $RenderItem-item-value-denominator-color;
    opacity: 0.45;
  }
}
.h2.RenderItemValue {
  font-size: 1.75rem;
}
p.RenderItemValue {
  font-size: 24px;
  padding-top: 0px !important;
}
