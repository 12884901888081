h1.title-page.pad-top {
  padding-top: 2rem;
}

h2.mb-bottom {
  margin-bottom: 50px;
}
.asked-questions {
  @media (max-width: 992px) {
    margin: 2.5rem 2.5rem;
  }
}

.faq-jumbotron {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  width: 100%;
  background-image: url('../../client_customizations/assets/images/faq-banner.svg');

  @media (max-width: 576px) {
    height: 250px;
  }

  .title-banner {
    padding: 0 199px;
    color: #fff;
    color: #e4ebf7;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    font-family: 'Proxima Nova';
    margin: 10px 220px 0px 0px;

    @media (max-width: 992px) {
      line-height: inherit;
      padding: 40px;
    }
  }
  .title-faq {
    font-family: 'Outfit';
    font-size: 46px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0em;
    color: #142c41;
    padding-top: 30px;
  }

  .subtitle-faq {
    font-family: 'Proxima Nova';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #142c41;
  }
}
.faqBgHolder {
  width: 100%;
  height: 744px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;

  z-index: -1;
}
