.MatchScoreOptions {
  @media (max-width: 768px) {
      background-color: #e3ebf7;
    }
  .h2 {
    font-family: "BentonSans", Helvetica, sans-serif;
    color: #142C41;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
}