@import "src/client_customizations/scss/client_master.scss";

$ButtonGroup-btn-active-background-color: #142C41;
$ButtonGroup-btn-active-background-color: #142C41;

.RateButtonGroup {
	.wrapper {
		width: 23%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
	}

	.btn {
		width: 100%;
		height: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		color: #000000 !important;
		background-color: transparent;
		border: 1px solid rgba(102, 99, 102, 0.33);
		border-radius: 4px;

		&:hover {
			background-color: $ButtonGroup-btn-active-background-color;
			border: 1px solid $ButtonGroup-btn-active-background-color;
			p.title, p.value {
				color: #FFFFFF !important;
			}
		}

		p.value {
			margin: 0 0 8px;

			font-family: 'Interstate', 'Open Sans' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 24px !important;
			line-height: 26px !important;
		}

		p.title {
			margin: 0;

			font-family: 'Interstate', 'Open Sans' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 16px !important;
			line-height: 26px !important;
		}

		&.large {
			width: 100%;
			margin: 0 0 8px;
		}
	}

	.btn.active {
		p.title, p.value {
			color: #FFFFFF !important;
		}

		border-radius: 0px;
		background-color: $ButtonGroup-btn-active-background-color;
	}

	span.description {
		margin: 4px 0 0;
		font-family: 'Interstate', 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;

		text-align: center;

		color: #000000;
	}
}
