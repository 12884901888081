$color_1: #07679b;
$color_2: #666666;
$color_3: #dbdbdb;
$color_4: #fff;
$color_5: #005f83;
$color_6: #0081c6;
$color_7: #2372b9;
$color_8: #007fa4;
$color_9: #142c41;
$color_10: #f0512c;
$font_family_1: 'Roboto';
$font_family_2: 'BentonSans';
$font_family_3: 'Outfit';
$font_family_4: 'Proxima Nova';

$background_color_1: #f8f8f8;
$background_color_2: white;
$background_color_3: #f2f2f2;
$background_color_4: #142c41;
$background_color_5: #f3f4f4;
$background_color_6: #0071b7;
$background_color_7: #ee1c2e;
$background_color_8: #e3ebf7;
$border_color_1: transparent;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 100;
  src: local('Outfit Thin'),
    url('../../assets/Outfit-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 200;
  src: local('Outfit Light'),
    url('../../assets/Outfit-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  src: local('Outfit'),
    url('../../assets/Outfit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  src: local('Outfit Medium'),
    url('../../assets/Outfit-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  src: local('Outfit Bold'),
    url('../../assets/Outfit-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 100;
  src: local('Proxima Nova Thin'),
    url('../../assets/Mark Simonson  Proxima Nova Thin.otf') format('opentype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 200;
  src: local('Proxima Nova Light'),
    url('../../assets/Mark Simonson  Proxima Nova Light.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: local('Proxima Nova'),
    url('../../assets/Mark Simonson  Proxima Nova Regular.otf')
      format('opentype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  src: local('Proxima Nova Medium'),
    url('../../assets/Mark Simonson  Proxima Nova Semibold.otf')
      format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src: local('Proxima Nova Bold'),
    url('../../assets/Mark Simonson  Proxima Nova Bold.otf') format('opentype');
}
@font-face {
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 100;
  src: local('BentonSans Thin'),
    url('../../assets/BentonSans-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 200;
  src: local('BentonSans Light'),
    url('../../assets/BentonSans-Light.otf') format('opentype');
}

@font-face {
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 400;
  src: local('BentonSans'),
    url('../../assets/BentonSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 500;
  src: local('BentonSans Medium'),
    url('../../assets/BentonSans-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 700;
  src: local('BentonSans Bold'),
    url('../../assets/FuturaCyrillicBold.woff') format('opentype');
}

body {
  background-color: $background_color_1;
  font-family: 'BentonSans';
  font-size: 13px;
}
h1 {
  font-family: $font_family_3;
  font-size: 48px;
  color: $color_9;
  font-weight: 400;
}

h2 {
  font-family: $font_family_4;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 0px;
}
@media (max-width: 766px) {
  h1 {
    font-size: 32px !important;
  }
  h2 {
    font-size: 16px !important;
  }
  .title-head {
    font-size: 32px !important;
  }
  .title-sub {
    font-size: 16px !important;
  }
  .homepage-incentives-title {
    font-size: 32px !important;
  }
  .homepage-incentives-title-sub {
    font-size: 16px !important;
  }
}
.modal-title {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: $font_family_3;
}
.close {
  color: $color_9 !important;
}
h3 {
  font-size: 16px;
}
.h3 {
  font-size: 20px;
}
h4 {
  font-size: 12px;
}
.h4 {
  font-size: 12px;
}
h5 {
  font-size: 10px;
}
.h5 {
  font-size: 10px;
}
.lead {
  font-size: 24px;
}
a {
  color: $color_1;

  &:focus, &:focus-visible {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    outline: none;
  }
}
table {
  table-layout: fixed;
  tbody {
    th {
      font-weight: normal;
    }
    tr {
      th {
        &:first-child {
          text-align: left;
        }
      }
    }
  }
  tfoot {
    tr {
      th {
        &:first-child {
          text-align: left;
        }
      }
    }
  }
}
table.table-bordered {
  tfoot {
    td {
      font-weight: bold;
      border-top: 2px solid #dee2e6;
    }
    th {
      font-weight: bold;
      border-top: 2px solid #dee2e6;
    }
  }
  th {
    word-break: break-word;
  }
}
button.btn-sm {
  font-size: 80%;
}
.input-well {
  .button.btn-sm {
    font-size: 80%;
  }
  background-color: $background_color_2;
  border-radius: 5px;
  padding: 17px;
  .form-group {
    p {
      margin-bottom: 1rem;
    }
  }
  form {
    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  label {
    font-weight: 400;
    font-size: 14px;
    color: $color_9;
  }
  .label-style {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
  }
  .btn {
    display: inline-block;
    border: 1px solid $color_9;
    font-weight: 400;
    font-size: 14px;
    background-color: white;
    color: $background_color_4;
    border-radius: 5px;
    height: 40px;
  }
  .btn.active {
    background-color: $background_color_4;
    color: white;
    border: 1px solid $background_color_4;
  }
  .btn-wide {
    display: inline-block;
    border: 1px solid $color_9;
    font-weight: 400;
    font-size: 14px;
    background-color: white;
    color: $background_color_4;
    border-radius: 5px;
    width: 100% !important;
    margin-right: -2% !important;
    height: 40px;

    &:focus {
      outline: 3px solid #142c41;
    }
  }
  .btn-wide.active {
    background-color: $background_color_4;
    color: white;
    border: 1px solid $background_color_4;
  }
  .btn:focus {
    box-shadow: 0 0 0 0.2rem $color_9 !important;
  }
  .btn-block-container {
    .filter-label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      position: relative;
      .name {
        font-size: 14px;
      }
      .switch {
        position: absolute;
        right: 0;
      }
    }
    .btn {
      display: block;
      width: 100%;
      margin-bottom: 6px;
      text-align: left;
      .badge {
        background-color: $background_color_2;
        border: 1px solid #dbdbdb;
        margin-right: 5px;
        img {
          width: 20px;

          padding-right: 17px;
        }
      }
      .filter-label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;

        .name {
          display: inline-flex;
          white-space: nowrap;
        }
        .switch {
          position: absolute;
          left: 70%;
          .react-switch-bg {
            width: 50px !important;
          }
        }
      }
      .filter-switch {
        display: flex;
      }
      .first {
        margin-bottom: 10px;
      }
    }
  }
  .btn-grid-container {
    display: flex;
    flex-wrap: wrap;
    button {
      width: 48%;
      margin: 2%;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(2n + 1) {
        margin-left: 0;
      }
    }
  }
  .btn-ae {
    padding: 10px 40px;
    border-radius: 0;
    border: 1px solid $color_9;
    color: $color_9;
    font-weight: 600;
    background-color: $background_color_1;

    &:focus {
      outline: 4px solid orangered;
    }
  }
}
.btn-default {
  border: 1px solid #ced4da;
}
strong {
  font-weight: 500;
}
hr {
  border-top: 2px solid rgba(0, 0, 0, 0.8);
  margin: 0;
  padding: 0;
}
.arrow-up {
  top: calc((1rem - 1px) * -1);
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.3rem;
  &:before {
    position: absolute;
    display: block;
    content: '';
    border-color: $border_color_1;
    border-style: solid;
    top: 0;
    border-width: 0 1rem 1rem 1rem;
    border-bottom-color: $border_bottom_color_1;
  }
  &:after {
    position: absolute;
    display: block;
    content: '';
    border-color: $border_color_1;
    border-style: solid;
    top: 1px;
    border-width: 0 1rem 1rem 1rem;
    border-bottom-color: $border_bottom_color_2;
  }
}
.grey {
  background-color: $background_color_5 !important;
}
.white {
  background-color: white !important;
}
.platinum {
  background-color: #e4ebf7 !important;
}

.bottom-thick-margin {
  border-bottom: 2px solid #000000;
}
.color-primary {
  color: $color_6;
}
.btn-ae {
  padding: 10px 40px;
  border-radius: 0;
  font-weight: 600;
  background-color: $background_color_1;
  border: 1px solid $color_9;
  color: $color_9;
  &:hover {
    border-radius: 0;
    color: $color_4;
    font-weight: 600;
    text-decoration: none;
    background-color: $color_9;
  }
}

.btn-pseg {
  font-family: $font_family_2;
  padding: 10px 40px;
  border-radius: 0;
  color: $color_4;
  font-weight: 500;
  background-color: $background_color_4;
  border: none;
  font-size: 16px;

  &:hover {
    background-color: $background_color_2;
    border: 1px solid $color_9;
    display: inline-block;
    font-weight: 500;
    color: $color_9;
  }
}

.btn-pseg-secondary {
  padding: 10px 20px;
  border-radius: 0;
  color: $color_9;
  font-weight: 500;
  background-color: white;
  border: none;

  &:hover {
    border: 2px solid $color_10;
    font-weight: 500;
    background-color: $color_4;
    color: $color_10;
  }
}

.btn-100 {
  width: 100%;
  height: 54px;
  font-weight: 700 !important;
}

.PageLayout {
  a.btn-ae {
    &:hover {
      border-radius: 0;
      color: $color_4;
      font-weight: 600;
      text-decoration: none;
      background-color: $background_color_6;
    }
  }
}
a.btn-ae {
  &:hover {
    border-radius: 0;
    color: $color_4;
    font-weight: 600;
    text-decoration: none;
    background-color: $background_color_6;
  }
}

// .select-ae {
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	-ms-appearance: none;
// 	appearance: none;
// 	background-repeat: no-repeat;
// 	background-position: right 15px top 11px, right 10px top 1em;
// 	background-size: 15px 15px, 15px 15px;
// 	background-image: url(../../assets/images/icons/Link-arrow.png);
// }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .select-ae {
    background-size: 0;
  }
}
.btn-arrow {
  text-align: start;
  background: none;
  border: none;
  color: $color_9;
  font-weight: 400;
  font-size: 14px;

  &:focus {
    outline: 4px solid #142c41;
  }
}
#vehicle-sort-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../assets/images/icons/down_arrow.svg);
  background-position: 97.5%;
  background-size: 1em 1em;
  background-repeat: no-repeat;
  background-color: $background_color_1;
  margin-right: 1em;
}

.selected-subText {
  display: flex;
  margin: 10px;
  font-weight: 400;
  color: $color_9;
  font-size: 12px;
}
.shadowed {
  box-shadow: 0 20px 41px 0 rgba(0, 0, 0, 0.1);
}

.bold {
  font-weight: 700 !important;
}
.light {
  font-weight: 400 !important;
}
.small-centered {
  margin-left: auto;
  margin-right: auto;
  width: 60%;

  @media (max-width: 768px) {
    width: 95%;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

.incentive-label-select {
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.container-fluid-tight {
  padding: 2rem 8rem;
}

@media (max-width: 576px) {
  .container-fluid-tight {
    padding: 2rem 2rem !important;
  }
}

.grey {
  background-color: #f3f3f3 !important;
}
.bold {
  font-family: 'Gotham Bold';
}

.title-head {
  font-weight: 700;
  font-family: 'Gotham Bold';
  font-size: 32px;
}
.btn-maps {
  padding: 10px 40px;
  border-radius: 0;
  color: $color_4;
  font-weight: 600;
  background-color: $background_color_4;
  border: none;
}

@media (min-width: 768px) {
  .map-options {
    .zip-options {
      display: flex;
      flex-direction: column;
    }
    .power-options {
      margin-left: 50px;
    }
  }
  #select-vehicle-power-filter,
  #travel-radius-map-address-input {
    font-weight: 700;
    color: #666666;
    width: 218px;
  }
  .btn-maps {
    margin-top: 26px;
    margin-left: 30px;
    width: 240px;
  }
  @media (max-width: 576px) {
    .btn-maps {
      margin-top: auto;
      margin-left: 0px !important;
      width: 240px;
    }
  }
}
.input-working-zipcode {
  margin-top: 0 !important;
  .map-options {
    margin-top: 0 !important;
  }
  .form-group {
    margin-bottom: 0 !important;
  }
  @media (max-width: 576px) {
    .form-group {
      margin-bottom: 10px !important;
    }
  }
}

.input-working-zipcode label {
  font-family: 'Gotham Bold';
  text-transform: capitalize;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
  margin-bottom: 8px;
}
.evHead {
  display: flex;
  justify-content: center;
  font-family: 'Outfit';
  color: $color_9;
  font-size: 48px;
}
.compare-select-title {
  margin-bottom: 0px !important;
  font-size: 14px;
  color: $color_9;
  font-family: $font_family_4;
  font-weight: 400;
}
.select-custom {
  .css-13cymwt-control, .css-t3ipsp-control {
    min-height: 40px;
    height: 59px;
    font-size: '14px';
    color: '#666666';
  }
  .css-1nmdiq5-menu {
    margin-top: 0px;
  }
}
.graph-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  margin: 0 0 30px;
  text-align: left;
  color: $color_9;
  font-family: $font_family_2;
}
.section-title {
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  margin: 0 0 30px;
  text-align: left;
  color: $color_9;
  font-family: $font_family_2;
}

.BreakevenChart {
  border: 1px solid #9ba4b8;
  padding: 2.5rem;
  margin-bottom: 50px;
}
.bordered {
  border: 1px solid #9ba4b8;
}

.bottom-margin {
  margin-bottom: 30px !important;
}
.top-margin {
  margin-top: 30px !important;
}

.MatchScoreOptions.margin-top {
  margin-top: 100px !important;

  @media (max-width: 768px) {
    margin-top: 0 !important;
  }
}

.MatchScoreOptions {
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}

.show-hide-btn-arrow {
  width: 100%;
  border-radius: 0;
  font-weight: 600;
  padding: 0.75rem 2rem;
  color: #2c61ae !important;
  background-color: $background_color_5 !important;
  border: 1px solid $color_3 !important;
  background: url(../../assets/images/blue-triangle.svg);
  background-position: 95%;
  background-size: 0.75em 0.75em;
  background-repeat: no-repeat;
}
.graph-sub-title {
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-family: $font_family_3;
  color: $color_9;
}
.compare-title {
  margin: 30px 0px 50px 0px;
  font-family: 'Outfit';
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #65a47a;
}

.line-box {
  display: flex !important;
}
.savings-info {
  font-weight: 400;
  font-size: 18px;
  margin-top: 5%;
}

.chart-info {
  width: 85%;
}
.img-fluid {
  width: 100%;
}
.unClick {
  pointer-events: none !important;
}

#ev-jumbotron-title,
#charging-map,
#incentives,
#EVs,
#home-charging-advisor,
#events {
  background-color: $background_color_8 !important;
}

.map-options {
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-around;

  label {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    margin-bottom: 8px;
    color: $color_9;
  }

  input {
    max-width: 300px;
  }

  .power-options {
    margin: 16px 0 0;
  }

  .form-check {
    margin-top: 0;
  }
  @media (max-width: 576px) {
    .form-check {
      margin-bottom: 5px;
    }
  }

  button {
    width: 100%;
    max-width: 300px;
    height: 42px;

    font-family: $font_family_3;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    background-color: $color_9;

    margin: 12px 0 0;
    @media (max-width: 576px) {
      height: 100%;
    }
  }
}

.switch {
  .react-switch-bg {
    border: 1px #403382 solid;
  }

  .react-switch-handle {
    top: 2px !important;
    left: 1px !important;
    height: 24px !important;
    width: 24px !important;
  }
}

input[type='checkbox'] {
  accent-color: $color_9;

  &:focus {
    outline: 3px solid orangered;
  }
}

.form-check-input {
  width: 24px;
  height: 24px;
}

.availableNowContainer {
  display: flex;
  align-items: center;
}

.evContainer {
  row-gap: 5px;
  display: flex;
  flex-direction: column;
}

.backButton {
  font-family: 'Outfit';
  color: #142c41;
  font-weight: bold;
  display: flex;
  gap: 5px;
  font-size: 16px;

  img {
    width: 20px;
  }

  &:hover {
    color: #696c72;
    text-decoration: none;
    cursor: pointer;
  }
}

.containerPaymentButtons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.EVIncentives {
  background-color: white !important;
}

.graph-container {
  display: flex;
  flex-direction: column;
  row-gap: 27px;
  overflow: hidden;
}

.title-map {
  color: $color_9;
  text-align: center;
  font-family: $font_family_3;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 121.739% */
}

.subtitle-map {
  color: $color_9;
  text-align: center;
  font-family: $font_family_4;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 128.571% */

  max-width: 751px;
}

.align-items {
  align-items: center;
}

.currency-wrap {
  position: relative;
}

.currency-code {
  position: absolute;
  left: 18px;
  top: 20px;
}

.unit-code {
  position: absolute;
  left: 62px;
  top: 20px;
}

.text-currency {
  padding: 10px 30px;
  border: solid 1px #ccc;
  border-radius: 5px;
}
.FHA-Link {
  color: #fff;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .css-1hb7zxy-IndicatorsContainer {
    padding-right: 3px !important;
  }
}
@media (max-width: 768px) {
  .modal-content {
    width: 99%;
  }
}
.modal-header {
  background-color: #fff;
  color: $color_9;
  border-bottom: 1px solid #9ba4b8;

  .close {
    color: #142c41;
  }
}
.disclaimer-modal {
  padding: 15px;
}
.white {
  background-color: #ffffff !important;
}
.RateOptionsWizard {
  .lead {
    font-family: $font_family_3 !important;
    font-weight: 400 !important;
  }
  label {
    color: $color_9 !important;
  }
  p {
    color: $color_9 !important;
  }
  .text-muted {
    font-weight: 400 !important;
    color: $color_9 !important;
  }
}
.RateAdvisor {
  .sub-header {
    font-family: 'Proxima Nova';
    font-size: 24px;
    font-weight: 400;
  }
}
