@import 'src/client_customizations/scss/client_master.scss';

/* evc-card */
/* Card Layouts */

button,
.btn {
  &:focus {
    // box-shadow: $PageLayout-button-focus-box-shadow;
    outline: 0;
  }
}
.pageHeaderBiLine,
.pageHeaderSubHeading {
  font-size: 1rem;
}
.form-control {
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../assets/images/icons/down_arrow.svg);
  background-position: 97%;
  background-size: 1em 1em;
  background-repeat: no-repeat;
  height: 59px;
  font-size:16px;

  &.zip {
    background: none;
    background-color: white;
  }
  &:focus {
    // box-shadow: $PageLayout-form-control-focus-box-shadow;
    outline: 0;
  }
}

.form-control-input {
  background: inherit;
  margin-bottom: 12px;
}

.ComparedVehicle {
  .select-fuel {
    .css-13cymwt-control , .css-t3ipsp-control{
      height: 59px;
    }
  }
  .form-control {
    background-color: white;
    font-size: 14px;
    color: #666666;
  }
}
.map-control {
  background: none !important;
}
input {
  &:focus {
    // box-shadow:$PageLayout-input-focus-box-shadow;
    outline: 0;
  }
}
select {
  &:focus {
    // box-shadow: $PageLayout-select-focus-box-shadow;
    outline: 0;
  }
}
textarea {
  &:focus {
    // box-shadow: $PageLayout-textarea-focus-box-shadow;
    outline: 0;
  }
}
.image-gallery-thumbnail {
  &:focus {
    // box-shadow:$PageLayout-image-gallery-thumbnail-focus-box-shadow;
    outline: 0;
  }
}
.SimpleCopyPageBody {
  .card {
    &:focus {
      // box-shadow: $PageLayout-SimpleCopyPageBody-card-focus-box-shadow;
      outline: 0;
    }
    background-color: $PageLayout-SimpleCopyPageBody-card-background-color;
    &:-webkit-any-link {
      &:hover {
        color: $PageLayout-SimpleCopyPageBody-card-link-hover-color;
        text-decoration: none;
      }
    }
    a {
      &:hover {
        color: $PageLayout-SimpleCopyPageBody-card-link-hover-color;
        text-decoration: none;
      }
    }
  }
  padding-bottom: 400px;
  p.h4 {
    color: $PageLayout-SimpleCopyPageBody-card-header-color;
    margin-top: 30px;
    font-weight: 500;
  }
}
section.container.white-background {
  background-color: white;
}
.easy-charging-home,
.subscription-service-pilot,
.charge-ev-overnight,
.meter-diagrams,
.charging-perks {
  font-size: 1rem;

  ul {
    margin: 0;
    list-style-image: url('../../assets/images/bullet.svg');
  }

  section.overview {
    background-color: $PageLayout-container-background-color;
  }

  section.container.no-pad {
    padding: 0;
    margin: 0;
  }

  section.container {
    padding-bottom: 0;
  }

  .colon-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  .grand-titled-para {
    margin-bottom: 3rem;
  }

  .grand-titled-para {
    .last-para {
      margin-bottom: 0 !important;
    }
  }
}
.evc-card {
  &:focus {
    // box-shadow: 0 0 0 4px #f15c2a;
    outline: 0;
  }
  background-color: $PageLayout-evc-card-focus-box-shadow;
  font-size: $PageLayout-evc-card-font-size;
  border: $PageLayout-evc-card-border;
  // border-radius: 4px;
  color: $PageLayout-evc-card-color;
  width: 100%;
  display: inline-block;
  &:hover {
    // border: $PageLayout-evc-card-hover-border;
    // box-shadow: $PageLayout-evc-card-hover-box-shadow;
    color: $PageLayout-evc-card-hover-color;
    text-decoration: none;
  }
}
.SocialMediaShareButton {
  &:focus {
    // box-shadow: $PageLayout-socialmedia-focus-box-shadow;
    outline: 0;
  }
}
.clickable-faq {
  &:focus {
    // box-shadow: $PageLayout-faq-focus-box-shadow;
    outline: 0;
  }
  color: $PageLayout-faq-focus-color;
  display: block;
  text-decoration: none;
  padding: 1rem;
  cursor: pointer;
}
.evc-card.selected {
  box-shadow: none;
}
#logo {
  &:focus {
    box-shadow: none;
  }
}
section.container {
  padding: 2rem 1rem;
}

.container {
  max-width: 1600px !important;
       -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
       box-sizing: border-box;
}
section.container-fluid {
  background-color: #e3ebf7 ;
  padding: 4rem 1rem;

  .page-title {
    font-family: 'BentonSans' !important;
    font-size: 46px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: center;
    color: #2372B9;
    text-transform: uppercase;

    &.left {
      text-align: left;
    }

    strong {
      font-weight: 600;
    }
  }

  p.map-sub-title {
    font-family: 'BentonSans' !important;
    font-size: 20px;
    line-height: 1.3em;
  }

  .page-subtitle {
    font-family: 'BentonSans';
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;

    color: #142C41;

    margin: 16px auto 32px;
  }

  @media (max-width: 450px) {
    padding: 4rem 1rem;
  }
}
.hide-offscreen {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
}
.clear-bottom-margin {
  margin-bottom: 0 !important;
}
.small-bottom-margin {
  margin-bottom: 5px !important;
}
.legal-disclaimer {
  font-size: 12px;
  letter-spacing: 0;
  color: $PageLayout-legal-disclaimer-color;
}
.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 50px;
}
.renderRowOfData {
  display: flex;
  flex-direction: column;
  p {
    span {
      float: right;
      font-weight: 500;
    }
  }
}
.h6 {
  strong {
    color: $PageLayout-header-strong-color;
  }
}
.SimpleCopyPageHeader {
  color: $PageLayout-SimpleCopy-color;
}
a.supporting-link {
  color: $PageLayout-SimpleCopy-color;
  margin-top: 0;
  font-weight: 700;
  font-size: 12px;
}
.evc-card.not-selected {
  height: 200px;
}
a.evc-card {
  &:hover {
    // border: $PageLayout-evc-card-hover-border;
    box-shadow: $PageLayout-evc-card-hover-box-shadow;
    color: $PageLayout-evc-card-hover-color;
    text-decoration: none;
  }
}
.render-cards-container {
  display: flex;
  flex-wrap: wrap;
  .evc-card {
    width: 48%;
    margin: 1%;
  }
}

.row-item {
  .inventory-card-details > .row {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .inventory-card {
    padding-bottom: 16px;
  }

  .inventory-card-details {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  section.container {
    padding: 2rem 2rem;
  }

  .row-item {
    .inventory-card-details > .row {
      .detail-title {
        height: 42px;
        align-items: flex-end;
      }
    }
  }
}
@media (min-width: 992px) {
  .render-cards-container {
    .evc-card {
      width: 49%;
      margin: 1%;
    }
    > * {
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(2n + 1) {
        margin-left: 0;
      }
      &:first-child {
        margin-top: 0;
      }
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
  .render-cards-container.full-width {
    .evc-card {
      width: 23.5%;
      margin: 1%;
    }
    > * {
      &:nth-of-type(4n) {
        margin-right: 0;
      }
      &:nth-of-type(4n + 1) {
        margin-left: 0;
      }
    }
  }
}
@media (min-width: 1200px) {
  .row-item {
    .inventory-card-details > .row {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }
  .inventory-card {
    padding-bottom: 0px;
  }

  .evc-card.not-selected {
    height: 220px;
  }
  .render-cards-container {
    .evc-card {
      width: 32%;
      margin: 1%;
    }
    > * {
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      &:nth-of-type(3n + 1) {
        margin-left: 0;
      }
      &:first-child {
        margin-top: 0;
      }
      &:nth-child(2) {
        margin-top: 0;
      }
      &:nth-child(3) {
        margin-top: 0;
      }
    }
  }
}
.evCompare-disclaimer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.layout-btn-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
  .link-btn {
    padding: 16px 36px;
    background: #142C41;
    border: none;

    font-family: "Outfit";
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: white;

    cursor: pointer;

    @media(max-width: 500px) {
      padding: 16px 12px;
    }
  }
}