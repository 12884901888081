.discover-section {
  padding:50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  row-gap: 60px;
  background-color: #E4EBF7;
  overflow: hidden;
}

.discover-container {
    display: flex;
    row-gap: 17px;
    max-width: 1100px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;  

  .title-head {
       color:#142C41;
  }
}

.title-head {
  font-family: 'Outfit';
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3em;
  flex-wrap: nowrap !important;
  @media (max-width: 576px) {
      text-align: center;
    }
}

.title-head-discover {
  font-family: "DM Serif Display";
  color: #142C41;
  font-style: italic;
  font-weight: 600;
}

.title-sub {
  font-family: 'Proxima Nova';
  display: flex;
  justify-content: center;
  color: #142C41;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  max-width: 800px;
}

.car-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top:50px;
}

.car-list {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;
  max-width: 1180px;
  overflow: hidden;
  flex-wrap: nowrap;
}

.inner-car-slider {
       display: flex;
       gap: 20px;
       justify-content: center;

}

.flip {
  transform: scaleX(-1);
}