@import 'src/client_customizations/scss/client_master.scss';
.Footer {
  background-color: $GenericFooter-background-color;
  padding: 20px;
  overflow: hidden;
  color: $GenericFooter-color;

  @media (max-width: 991px) {
    padding: 15px;
  }

  a {
    color: rgb(255, 255, 255);
    text-decoration: underline;
    white-space: nowrap;
    margin: 0px 20px 15px 0px;
  }
  .disclaimer_container {
    font-family: Arial, Helvetica;
    display: flex;
    align-items: center;
    margin-left: '5px';
    font-size: 10px; /* Set the font size to 10px */

    div {
      background-color: transparent !important;

      img {
        max-width: 50%;
        margin: 0 0 15px;
      }

      p {
        color: white;
      }
    }
  }

  .subtext {
    font-family: 'BentonSans' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 171.429% */
    text-transform: uppercase;
  }
  .horizontal-line {
        margin: 40px 0;
          height: 1px;
          width: 100%;
          background-color: #d2d3d5;
  }

  .social-media-row {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: space-between;

    @media(max-width:1050px) {
       flex-wrap: wrap;
    }

    .privacy-links {
      font-size: 16px;
      display: flex;
      text-align: left;
      flex-wrap: wrap;
      position: relative;
    }
    .social-section {
      font-size: 16px;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      margin-top: 0;

      @media(max-width:450px) {
              width: 100%;
              flex-direction: column;
              align-items: center;
      }

      a {
       margin:0 10px;
        img {
          height: 20px;
        }
      }
    }
    
  }

  .legal-info {
    text-align: left;
  }
}
